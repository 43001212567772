.ppt_step1 {
  .tag {
    display: flex;
    align-items: center;
    height: 38px;
    border-radius: 6px;
    padding: 0 16px;
    color: #9557FF;
    background: #F4EEFF;
    cursor: pointer;
    &.active {
      background: #9557FF;
      color: #FFFFFF;
    }
  }
  :global{
    .upload_container {
      margin-top: 40px;
      .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-left: 8px;
        position: relative;
        &:before {
          display: block;
          content: ' ';
          position: absolute;
          left: 0;
          top: calc(50% - 5px);
          width: 2px;
          height: 10px;
          border-radius: 0 2px 2px 0;
          background: #9557FF;
        }
      }
      .content {
        width: 100%;
        height: 260px;
        border-radius: 8px;
        background: #F8FBFF;
        box-sizing: border-box;
        border: 1px dashed #DBE0EC;
        margin-top: 12px;
        &:hover {
          border: 1px dashed #9557FF;
        }

        .ant-upload {
          border: none;
          background: none;
          .name {
            margin-top: 12px;
            font-size: 16px;
            line-height: 24px;
          }
          .tip {
            margin-top: 4px;
            font-size: 12px;
            line-height: 20px;
            color: #8C93B1;
          }
          .btn {
            margin-top: 24px;
            width: 224px;
          }
        }
      }
    }
  }

  .geneBtn {
    margin: 48px 0 0 0;
    width: 240px;
    height: 56px;
  }
}
