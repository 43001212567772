.error {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;

  .img {
    width: 200px;
    height: auto;
    margin-bottom: 20px;
  }

  .tip {
    font-size: 10px;
    margin: 10px 0 0 0;
    color: #999;
  }
}
