.searchPaper {
    width: 1108px;
    margin: 0 auto;

    display: flex;     // 添加弹性布局
    flex-direction: column; // 垂直方向排列
    padding-bottom: 40px; // 给底部留些空间
    height: 100%;
    overflow: auto;

    .boxCard {
        border-radius: 26px;
        background-color: #fff;
        margin-top: 16px;
        margin-bottom: 16px;
        position: relative;
        width: 100%;
        box-sizing: border-box;
        padding: 0 16px;
    }

    .paperTitleWrapper {
        position: relative;
        border-bottom: 1px dashed #e5e5e5;
        padding: 24px 0;
    }

    .paperTitle {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        color: #333;
        text-align: center;
    }

    .paperOptimize {
        width: 91px;
        height: 34px;
        border-radius: 18.5px 0px 0px 18.5px;
        background: linear-gradient(270deg, #f7941d 0%, #ffb863 101%);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 23px;
        right: -16px;
        color: #fff;
        cursor: pointer;

        .optDesc {
            margin-left: 4px;
        }

        &:hover {
            background: linear-gradient(270deg, #f7941d 0%, #ffb863 101%);
        }
    }

    .summaryFooter {
        border-top: 1px dashed #e5e5e5;
        padding: 24px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        color: #999;

        .genBtn {
            width: 136px;
            height: 46px;
            border-radius: 40px;
            margin-bottom: 12px;
            font-size: 16px;
            font-weight: normal;
            line-height: 24px;
            text-align: center;
        }

        .xiaohao {
            display: block;
            font-size: 14px;
            font-weight: normal;
            line-height: 18px;
            text-align: center;
            color: #f7941d;

            b {
                font-weight: normal;
                font-size: 10px;
                color: #999999;
                display: block;
            }
        }

        .regetOutline {
            cursor: pointer;
        }
    }

    .agreements {
        color: #999;
        display: flex;
        justify-content: center;
        margin-bottom: 27px;

        :global(.ant-checkbox-wrapper) {
            color: #999;
        }
    }
}

.titleModals {
    padding: 10px 0;
    min-height: 30px;

    .titles {
        background-color: rgba(242, 242, 245, 0.4);
        padding: 30px 32px;
    }

    .subTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
        text-align: center;

        .icon {
            vertical-align: -2px;
            margin-left: 2px;
        }
    }

    .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #ffffff;
        border-radius: 3px;
        padding: 15px;
        margin-bottom: 8px;
        border: 1px solid transparent;

        &:hover {
            box-shadow: 0 1px 4px rgba(68, 90, 116, 0.25);
        }

        &.focus {
            // border-color: #ffb24782;
        }

        &.select {
            border-color: #ffb347;
            background-color: #ffb24713;
        }

        .checked {
            color: #ffb347;
            font-size: 24px;
            padding: 0 30px;
        }
        :global(.ant-input-data-count) {
            transform: translate3d(-12px, -3px, 0px);
            font-size: 12px;
        }
    }
}

// 移动端样式
@media only screen and (max-device-width: 600px) {
    .searchPaper {
        width: 100%;
        .boxCard {
            padding: 0;
        }
    }
}
