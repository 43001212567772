/* 表格样式 */
.table-modal {
    table {
        font-size: 13px;
        border-collapse: collapse;
        margin: 0.5rem 0;
        width: 100%;
        table-layout: fixed;
        border: 1px solid #ddd;
    }

    th,
    td {
        padding: 6px 8px;
        text-align: left;
        min-width: 100px;
        word-break: break-all;
        position: relative;

        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;

        &:first-child {
            border-left: none;
        }
    }

    tr:first-child th,
    tr:first-child td {
        border-top: none;
    }

    th {
        background-color: #f5f5f5;
        font-weight: bold;
    }

    td:empty::after {
        content: '\00a0';
        visibility: hidden;
    }
}
