.ppt {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    width: 1108px;
    border-radius: 26px;
    background: #ffffff;
    margin: 0 auto;
    padding: 24px;
    margin-top: 16px;
    margin-bottom: 32px;

    .step3 {
        .title {
            color: #333333;
            font-weight: 500;
            line-height: 26px;
            font-size: 18px;
            display: flex;
            align-items: center;
            margin-bottom: 24px;

            .titleDesc {
                color: #999999;
                font-size: 12px;
            }
        }

        .templateBox {
            display: flex;
            flex-wrap: wrap;

            .template {
                width: 253px;
                border-radius: 16px;
                background: rgba(255, 255, 255, 0.8);
                overflow: hidden;
                border: 1px solid transparent;

                margin: 0 16px 16px 0;
                position: relative;

                &.active {
                    border: 2px solid #ff6c3a;
                }
                &:nth-child(4n) {
                    margin-right: 0;
                }

                .templateImg {
                    border-radius: 16px;
                    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

                    .img {
                        width: 253px;
                    }
                }

                .select {
                    position: absolute;
                    right: 12px;
                    bottom: 12px;
                }

                .templateTitle {
                    border-radius: 0 0 20px 20px;
                    z-index: 11;
                    width: 100%;
                    height: 56px;

                    .inner {
                        padding: 16px 12px;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        border-radius: 0 0 16px 16px;
                        overflow: hidden;
                        backdrop-filter: blur(33px);
                        height: 100%;
                        justify-content: space-between;
                        background-color: rgba(255, 255, 255, 0.8);
                    }
                }
            }
        }
    }

    .paging {
        display: flex;
        justify-content: center;
    }

    .next {
        width: 239px;
        height: 56px;
        border-radius: 39.5px;
        margin-bottom: 30px;
    }
}

// 移动端
@media (max-width: 768px) {
    .ppt {
        width: 100%;
        border-radius: 0;
        .step3 {
            .templateBox {
                .template {
                    width: 100% !important;
                    margin-right: 0;
                    .templateImg {
                        width: 100% !important;
                        .img {
                            width: 100% !important;
                        }
                    }
                }
            }
        }
    }
}
