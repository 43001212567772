.input {
  width: 100%;
  display: flex;
  align-items: center;
}

.selects {
  margin: 16px 0 0 0;
}

.geneBtn {
  margin: 48px 0 0 0;
  width: 240px;
  height: 56px;
}

.subTitle2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;

  .icon {
    vertical-align: -2px;
    margin-left: 2px;
  }
}

.wenxianCheck {
  width: 100%;
  margin: 16px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(87, 87, 87);

  .mr8 {
    margin-right: 8px;
  }

  .switchRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 8px auto;

    .switchLabel {
      margin: 0 4px 0 0;
    }
  }

  .switch-input {
  }
}

.summaryIcon {
  margin-left: 4px;
  cursor: pointer;
}

.tipTitle{
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 12px;
}
