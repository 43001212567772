.result {
  flex: 1;
  overflow: auto;

  .resultPage {
    background-color: #fff;
    border-radius: 10px;
    margin: 0 auto;
    position: relative;
  }

  .pageTitle {
    margin-top: 0px;
    margin-bottom: 40px;
    text-align: center;
    line-height: 150%;
    font-size: 36px;
  }

  .chapterTitle {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
  }
  .section {
    font-size: 18px;
    margin: 10px 0 10px 0;
    font-weight: bold;
    line-height: 32px;
  }
  .subHeading {
    font-size: 16px;
    margin: 10px 0;
    line-height: 32px;
  }

  .content {
    white-space: pre-line;
    word-break: break-all;
    line-height: 32px;
    font-size: 16px;

    span {
      vertical-align: text-bottom;
      font-size: 12px;
    }
  }

  .pay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .payButtonBox {
    position: relative;
    margin: 20px 0 10px 0;
    .payMask {
      background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff);
      height: 200px;
      width: 100%;
      position: absolute;
      top: -200px;
    }
  }
}

.demoList {
  background: #eef8f5;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;

  width: 500px;
  margin: 0 auto;
  margin-bottom: 20px;

  :global(.slick-dots-left) {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}

@media only screen and (max-device-width: 1024px) {
  /* 在此添加针对iPad的CSS样式 */
  .result {
    .resultPage {
      width: 100%;
      padding: 10px 15px;
    }

    .demoList {
      width: 100%;
      margin-bottom: 0;
    }
  }
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 16px;
}

.extraBox {
  display: flex;
  flex-wrap: wrap;
}
.extra {
  width: calc(33% - 16px);
  background-image: linear-gradient(to right, #f7951d2e, rgb(255, 255, 255));

  border: 1px solid #f7951d50;
  margin-right: 27px;
  padding: 8px 16px;
  border-radius: 6px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-child(3n) {
    margin-right: 0;
  }

  .name {
    font-size: 14px;
    line-height: 21px;
  }

  .desc {
    line-height: 22px;
    display: flex;
    align-items: center;
  }

  .price {
    color: rgb(238, 99, 96);
  }

  .origin {
    text-decoration: line-through;
    font-size: 12px;
    color: #999;
    margin-left: 4px;
    line-height: 12px;
    margin-top: 2px;
  }
}

.payContent {
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;

  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .right {
    margin: 0 0 0 60px;
    flex: 1;
    position: relative;
    &::before {
      position: absolute;
      width: 150px;
      height: 150px;
      display: block;
      content: "";
      right: 0;
      top: -40px;
      background-size: 100% 100%;
      z-index: 1;
    }

    .title {
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 8px;
    }

    label {
      font-weight: 500;
      flex-shrink: 0;
      margin-right: 20px;
    }

    .row {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: right;
      position: relative;
      z-index: 2;
    }

    .price {
      font-weight: 500;
      font-size: 16px;
      text-align: right;
    }

    .origin {
      text-decoration: line-through;

      color: rgb(153, 153, 153);
      margin-left: 6px;
      font-weight: 500;
      font-size: 12px;
    }

    .btnRow {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 30px 0 0 0;
    }
    .btn {
      margin: 0 auto;
    }
  }
}

.payResult {
  .payStatusBox {
    display: flex;
    flex-direction: column;
    align-items: center;

    .statusTitle {
      font-weight: 500;
      font-size: 16px;
      text-align: center;
    }

    .statusImg {
      width: 200px;
      height: auto;
    }
  }
}

.loadingDot {
  width: 60px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #000 90%, #0000) 0 /
    calc(100% / 3) 100% space;
  clippath: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
  @keyframes l1 {
    to {
      clippath: inset(0 -34% 0 0);
    }
  }
}

.discount {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  font-size: 16px;

  .line {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .lineLeft {
      background: linear-gradient(
        90deg,
        rgba(30, 153, 255, 0) 0%,
        #f59d8f 100%
      );
      width: 100px;
      height: 2px;
    }

    .lineRight {
      background: linear-gradient(to right, #f59d8f, rgba(30, 153, 255, 0));
      width: 100px;
      height: 2px;
    }
  }

  .countdown {
    color: #ff4242;
    font-weight: 800;
  }
}

.xiaohao {
  display: block;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  text-align: center;
  color: #f7941d;
  margin: 10px 0 0 0;

  b {
    font-weight: normal;
    font-size: 10px;
    color: #999999;
    display: block;
  }
}
