.optIcon {
  width: 14px;
  height: 14px;
}
.opt {
  flex-shrink: 0;
  margin-left: 16px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  color: #999;
}

.iconBtn {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-right: 2px !important;
}

.iconActive {
  color: var(--colorPrimary);
}
