.example {
  position: relative;
  .anchor {
    position: fixed;
    top: 49px;
    left: 0;
    width: 100vw;
    right: 0;
    background-color: #fff;

    :global(.ant-anchor-link) {
      flex: 1;
      text-align: center;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 688px;
    margin: 0 auto;

    .title {
      span {
        color: var(--ant-color-primary);
      }
    }

    .imgBox {
      box-shadow: 1px 1px 6px #ccc;
      border-radius: 8px;
      border: 1px solid rgb(229, 231, 235);
      width: 688px;
      margin: 0 0 8px 0;
    }

    .anchorContent {
      padding: 100px 0 0 0;
    }
  }
}
