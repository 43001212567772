.result {
  flex: 1;
  overflow: auto;
  font-family: PingFang SC, Microsoft YaHei, sans-serif;

  .resultPage {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.08);
    padding: 60px 20px;
    width: 900px;
    margin: 0 auto;
    position: relative;
  }

  .pageTitle {
    margin-top: 0px;
    margin-bottom: 40px;
    text-align: center;
    line-height: 150%;
    font-size: 36px;
  }

  .chapterTitle {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
  }
  .subHeading {
    font-size: 16px;
    margin: 10px 0;
    font-weight: bold;
  }

  .content {
    white-space: pre-line;
    word-break: break-all;
    line-height: 32px;
    font-size: 16px;
  }

  .pay {
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 100%;
    z-index: 9999;

    .payContent {
      width: 100%;
      height: 150px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #fffffff0 100%
      );
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #24be58;
      border-radius: 0 0 10px 10px;
      cursor: pointer;

      .payLeftBtn {
        width: 185px;
        height: 52px;
        background-size: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        background-color: #fff;
        color: #24be58;
        flex-direction: column;
        margin: 0 10px 0 0;
        border: 1px solid #24be58;
      }

      .payBtn {
        width: 185px;
        height: 52px;
        background-size: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
      }

      .payTip {
        margin-top: 10px;
        text-align: center;
        margin-left: -30px;
        display: flex;
        align-items: center;
      }

      .fuwuLink {
        text-decoration: none;
        color: #24be58;

        &:hover {
          color: #24be58 !important;
        }
      }
    }
  }

  .pptPreview {
    position: relative;
    width: 806px;
    height: 457px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .pptPreviewContent {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 99;
    padding: 28px 46px;
    box-sizing: border-box;

    .pptTitle {
      font-size: 40px;
      line-height: 67px;
      color: #000000;
      font-weight: 700;
      margin-bottom: 17px;
      text-align: center;
      max-width: 700px;
    }

    .pptSubTitle {
      font-size: 28px;
      line-height: 42px;
      margin-bottom: 51px;
      font-weight: 700;
      text-align: center;
    }
    .pptAuthor {
      font-size: 15px;
      line-height: 22px;
      color: #000000;
    }

    .createTime {
      font-size: 15px;
      line-height: 22px;
      color: #000000;
    }
  }
  .pptImg {
    width: 806px;
    height: 457px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.demoList {
  background: #eef8f5;
  border-radius: 8px;

  :global(.slick-dots-left) {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}

.payResultBtnList {
  display: flex;
}

@media only screen and (max-device-width: 600px) {
  /* 在此添加针对iPad的CSS样式 */
  .result {
    .resultPage {
      width: 100vw;
    }

    .pptPreview {
      width: 100%;
    }

  }
}
