.new {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex: 1;
  overflow: auto;
  padding: 16px;

  h1 {
    margin-top: 30px;
  }

  .step {
  }

  .progress {
    //padding: 20px 70px 0;
    //width: 824px;
    margin: 0 auto;
  }

  .content {
    //background-color: #fff;

    h4 {
      color: #1a1b4c;
      line-height: 23px;
      margin: 26px 0 20px;
      width: 100%;
      text-align: center;
    }
  }
}

.reduce {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #F6F8F9;

  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 100%;
    height: 55px;
    background: #fff;
    padding: 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    //justify-content: space-between;
    justify-content: center;
    flex-shrink: 0;
    color: #00CE72;
    font-size: 18px;
    font-weight: bold;

    .back {
      width: 76px;
      padding: 0 5px 0px 10px;
      border-radius: 14px;
      cursor: pointer;
      text-align: center;
      font-size: 14px;
      line-height: 26px;
      box-sizing: border-box;
      height: 28px;
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .right {
      .wrapper {
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 3px;
        padding: 0 10px;
        height: 24px;
        font-size: 14px;
        color: rgba(1, 2, 53, 0.6);
        width: 250px;
        white-space: nowrap;
        background: rgba(246, 246, 246, 0.5);
        border: 1px solid rgba(220, 220, 220, 0.5);

        .count {
          color: #010235;
        }
      }
    }
  }
}

//.step1 {
//  width: 1102px;

.firstRow {
  display: flex;
  justify-content: space-between;

  .promise {
    text-align: center;
    color: #999999;
    font-size: 14px;
    margin-top: 16px;
  }
}

.secondRow {
  margin: 20px 0 0 0;
  padding: 30px;

  .title {
    color: #404040;
    font-weight: 700;
    font-size: 14px;
  }

  .subtitle {
    color: #404040;
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .anli {
    display: flex;

    .anliItem {
      flex: 1;

      &:first-child {
        margin-right: 10px;
      }

      .anliTitle {
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        text-align: center;
        margin: 10px 0 0 0;
      }
    }
  }
}

.card {
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  height: 100%;
}

.uploadBox {
  width: 852px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.uploadContent {
  padding: 40px 0 100px 0;
}

.helpBox {
  //width: 230px;
  padding: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid #EEEEF1;

  .title {
    color: #404040;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
  }

  .tip {
    color: #404040;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 12px;
  }

  .btnBox {
    margin-top: 10px;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border: none;
  }

  .btn {
    width: 5.5rem;
    height: 36px;
    border-radius: 4px;
    font-size: 12px;
    background: #F8FBFF;
    border: none;
  }
}

// 手机响应式
@media screen and (max-width: 768px) {
  .new{
    width: 100%;
  }
  .helpBox {
    width: 100%;
    padding: 16px;
    background-image: url("../../assets/helperBg.png");
    background-size: contain;
    background-repeat: no-repeat;

    .title {
      color: #404040;
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 14px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 10px;
    }

    .tip {
      color: #404040;
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 12px;
    }

    .btnBox {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      border: none;
    }

    .btn {
      width: 100px;
      height: 34px;
      border-radius: 4px;
      //background-color: rgba(85, 195, 106, 0.1);
      //color: #55c36a;
      font-size: 12px;
      margin: 0 10px 10px 0;
      //border: none;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

.support {
  display: flex;
  align-items: center;
  margin: 32px 10px 0 10px;
  justify-content: center;

  .list {
    flex: 1;
    display: flex;
  }

  .card {
    display: flex;
    flex: 1;
    border-radius: 4px;
    background-color: #fff;
    margin-left: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #404040;
    margin-left: 10px;

    .descBox {
      margin: 0 0 0 4px;
      text-align: left;
    }

    .img {
      width: 34px;
      height: 34px;
    }

    .desc {
      margin-top: 5px;
      color: rgba(64, 64, 64, 0.4);
      font-size: 10px;
      text-align: left;
    }
  }
}

.hasFile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 346px;
  margin-top: 30px;
  position: relative;

  .successImg {
    width: 65px;
    height: 66px;
    margin-top: 77px;
  }

  .successTip {
    font-size: 14px;
    color: #000;
    margin-top: 14px;
  }

  .successBtns {
    display: flex;
    justify-content: center;
    margin-top: 26px;
    width: 100%;

    .leftBtn {
      margin-right: 60px;
    }
  }
}

//}

//.step2 {
//  display: flex;
//  flex-direction: row;
//  width: 1192px;
//  margin: 20px 0 0 0;

  .left {
    flex: 1;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    padding: 12px;
    min-height: 1000px;


    .h4 {
      margin-bottom: 20px;
    }

    .pra {
      width: 100%;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.02);
      padding: 16px;
      display: flex;
      margin: 0 0 10px 0;
      cursor: pointer;

      &.active {
        background-color: rgba(85, 195, 106, 0.05);
        color: #ce3232;
      }
    }
  }

  .right {
    width: 100% ;
    border-radius: 10px;
    padding: 16px;
    background-color: #fff;
    height: max-content;

    .title {
      color: #000;
      font-weight: 700;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .wordCount {
        //color: #f29423;
        color: #1890FF;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .h5 {
      font-size: 14px;
      color: #000;
      font-weight: 700;
      margin-top: 20px;
    }

    .item {
      padding: 13px 20px;
      border-radius: 10px;
      font-size: 14px;
      color: #404040;
      cursor: pointer;
      width: 100%;
      margin: 10px 0 10px 0;

      & > span:nth-child(2) {
        flex: 1;
      }

      &.active{
        border: 0.5px solid var(--colorPrimary);
        background: #F4EEFF;
      }

      .itemContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        b {
          color: var(--colorPrimary);
        }
      }
    }

    .payPrice {
      //color: #f29423;
      color: #FF4949;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .collapse {
    :global(.ant-collapse-header) {
      padding: 12px 0 !important;
    }

    :global(.ant-collapse-content-box) {
      padding: 0 0 12px 0 !important;
    }
  }

  .payRule {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    margin-bottom: 10px;
    font-size: 14px;

    .red {
      color: #ff3434;
      margin-right: auto;
    }
  }

  .payDesc {
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
  }

  .payRuleRow {
    margin: 12px 0;
  }

  .rightBtns {
    margin: 0 0 20px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 16px 0 0 0;

    .btn {
      margin: 20px 0 0 0;
      display: block;
      width: 100%;
    }

    .redeem {
      text-align: center;
      margin: 16px 0 0 0;
      color: #999;
      font-size: 14px;
      cursor: pointer;
    }
  }
//}

.step3 {
  .wait {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .waitImg {
    width: 228px;
    height: 180px;
    margin-top: 104px;
  }

  .tip {
    color: #213315;
    font-weight: 700;
    margin-top: 39px;
  }

  .waitDesc {
    color: #213315;
    font-size: 12px;
    margin-top: 20px;
  }

  .footer {
    display: flex;
    align-items: center;
    margin-top: 34px;
    padding-bottom: 30px;

    .rightBtn {
      margin: 0 0 0 20px;
    }
  }

  .reduceRes {
    width: 100%;
    background: #fff;
    border-radius: 16px;
    padding: 24px;

    .resHeader {
      display: flex;
      justify-content: space-between;
      margin: 30px 0 20px;

      .headerItem {
        width: 666px;
      }
    }

    .list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .listItem {
        flex: 1;
        min-height: 158px;

        .cardBox {
          background-color: #fff;
          border-radius: 10px;
          position: relative;
          padding: 20px;
          height: 100%;
          display: flex;
          flex-direction: column;
          line-height: 1.5;


          .text {
            width: 100%;
            border-radius: 10px;
            color: #404040;
            font-size: 14px;
            //background-color: rgba(0, 0, 0, 0.02);
            //padding: 16px;
            word-break: break-all;
            flex: 1;
          }

          .txtCnt {
            height: 32px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            color: #404040;
            margin-bottom: 10px;

            .copy {
              margin: 0 0 0 10px;
            }
          }
        }

        &.origin.active {
          .text {
            color: #ce3232;
          }
        }

        &.active {
          .txtCnt {
            color: #55c36a !important;
          }

          .text {
            //background-color: rgba(85, 195, 106, 0.05) !important;

          }
        }
      }
    }
  }

  .helpTopBg {
    background-image: url("../../assets/helpTopBg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .blockLeft {
    .cardBox {
      border-radius: 12px;
      border: 1px solid #f0f0f0;
      background: #fff;
    }
  }

  .blockRight {

    .cardBox {
      border: 1px solid #00CE72;
      border-radius: 12px;
      background: #F2FCF8 !important;
    }
  }

  .miniMap {
    position: fixed;
    left: 0;
    top: calc(50%);
    transform: translateY(-50%);
    // background-color: rgba(0, 0, 0, 0.13);
    z-index: 99;
    max-height: calc(100vh - 120px);
    width: 40px;
    overflow: visible auto;

    .mapWrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      height: fit-content;
      width: 14px;
      box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.13);
    }

    .anchor {
      width: 100%;
      flex-shrink: 0;
      margin-bottom: 1px;
      cursor: pointer;
      transition: transform 0.3s ease-in-out;
      background-color: #f8e5cc;
      height: 3px;

      &.highlight {
        background-color: #24be58;
        height: 5px;
      }

      &:hover {
        transform: scale(2);
      }
    }
  }
}
