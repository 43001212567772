.navWrapper {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    .nav {
        padding: 8px 24px 8px;
        display: flex;
        justify-content: space-between;
        transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);

        .logo {
            height: 40px;
            width: auto;
        }

        .content {
            display: flex;
            align-items: center;

            .item {
                margin-right: 20px;
                cursor: pointer;
            }
        }
    }
}

// 移动端样式
@media (max-width: 768px) {
    .navWrapper {
        a {
            img {
                height: 16px;
            }
        }
        .nav {
            padding: 12px;
            .logo {
                width: 76px;
                height: 28px;
            }

            .content {
                gap: 8px;
                .item {
                    margin-right: 0 !important;
                    cursor: pointer;
                }
            }
        }
    }
    .bannerImgText {
        font-size: 22px !important;
        line-height: 28px !important;
        margin-left: 2px !important;
    }
}

.oldVersionBanner {
    /* 优化过渡动画，明确指定需要过渡的属性 */
    transition:
        transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
        opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1),
        height 0.25s cubic-bezier(0.4, 0, 0.2, 1),
        margin 0.25s cubic-bezier(0.4, 0, 0.2, 1),
        padding 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
    width: 100%;
    height: 64px;
    opacity: 1;
    transform: translateY(0);
    transform-origin: top;
    background-image: url('../../assets/top-bg.png'), url('../../assets/top-bg--1.png');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* 添加性能优化提示 */
    will-change: transform, opacity, height;

    &.hidden {
        transform: translateY(-100%);
        opacity: 0;
        visibility: hidden;
        height: 0;
        margin: 0;
        padding: 0;
    }

    .bannerImg1 {
        margin-right: 2px;
        height: 32px;
        width: 38px;
    }

    .bannerImg2 {
        margin-right: 12px;
        height: 32px;
        width: 270px;
    }

    .bannerImgText {
        font-family: 'AlimamaShuHeiTi';
        font-size: 32px;
        font-weight: bold;
        line-height: 38px;
        letter-spacing: 0em;

        font-variation-settings: 'opsz' auto;
        font-feature-settings: 'kern' on;
        background: linear-gradient(180deg, #ffffff 0%, #e3d8ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 2px;
        text-shadow: 0px 1px 1px 0px #4901fc;
    }

    .bannerImg3 {
        margin-bottom: 4px;
        height: 30px;
        width: 180px;
        cursor: pointer;
    }

    .bannerImg4 {
        margin-bottom: 32px;
        margin-left: 4px;
        height: 16px;
        width: 16px;
        cursor: pointer;
    }

    @media (max-width: 768px) {
        height: 32px;
        padding: 0 12px;

        &.hidden {
            height: 0;
            margin: 0;
            padding: 0;
        }

        .bannerImg1 {
            margin-right: 2px;
            height: 16px;
            width: 19px;
        }

        .bannerImg2 {
            margin-right: 6px;
            height: 16px;
            width: 135px;
        }

        .bannerImg3 {
            margin-bottom: 2px;
            height: 15px;
            width: 90px;
        }

        .bannerImg4 {
            margin-bottom: 16px;
            margin-left: 2px;
            height: 8px;
            width: 8px;
        }
    }
}
