.documents {
    position: relative;

    .bg {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        //background: linear-gradient(180deg, rgb(143 68 214 / 13%), #fff0 56%);
        // 背景图
        background-image: url('../../assets/bg-normal.png');
        background-size: cover;
    }

    .bgUltra {
        background-image: url('../../assets/bg-ultra.png');
        //background: linear-gradient(180deg, rgb(247 148 29 / 8%), #fff0 56%);
    }

    .content {
        position: relative;
        z-index: 1;
        margin: 0 auto;
        //max-width: 1200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 100px 0 20px 0;

        .title {
            font-size: 48px;
            font-weight: 800;
            margin-bottom: 24px;
        }

        .subTitle {
            font-size: 18px;
            color: #414c6f;
            margin-bottom: 48px;
        }

        .opts {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin: 60px 0 0 0;
            max-width: 90vw;

            :global(.ant-input-data-count) {
                transform: translateY(7px);
                font-size: 14px;
            }
        }

        .stepFormContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 40px 100px;
            background-color: #fff;
            box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.03);
            border-radius: 0 12px 12px 12px;
        }

        .thirdSummary {
            display: flex;
            align-items: center;
            margin: 16px 0 0 0;
            color: #999999;

            .summaryTip {
                margin: 0 0 0 4px;
            }
        }

        .listDesc {
            width: 720px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 64px 0 0 0;
            max-width: 90vw;

            .listItem {
                width: 40%;
                margin: 8px 0;
                display: flex;
                align-items: center;

                &:nth-child(2n) {
                    width: 50%;
                }
            }

            .listIcon {
                width: 16px;
                height: 16px;
                border-radius: 4px;
                background-color: var(--ant-color-primary);
                color: #fff;
                display: block;
                text-align: center;
                font-size: 12px;
                line-height: 16px;
                margin: 0 4px 0 0;
                flex-shrink: 0;
            }
        }
    }

    .policyCheck {
        width: 100%;
        margin: 16px 0 20px 0;
        width: 720px;
        max-width: 90vw;
    }

    .ringingBox {
        background-color: rgba(0, 0, 0, 0.05);
        padding: 10px;
        margin-bottom: 32px;
        margin-top: 40px;
        max-width: 90vw;

        span {
            color: var(--ant-color-primary);
        }
    }

    .toggleVersion {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    .loading {
        margin: 20px auto;
    }
}

.bannerText {
    font-family: 'AlimamaShuHeiTi', sans-serif;
    font-size: 38px;
    font-weight: bold;
    line-height: 38px;
    letter-spacing: 0em;

    font-variation-settings: 'opsz' auto;
    font-feature-settings: 'kern' on;
    background: linear-gradient(90deg, #7321ff 0%, #ff18c1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.ultraBannerText {
    font-family: 'AlimamaShuHeiTi', sans-serif;
    font-size: 38px;
    font-weight: bold;
    line-height: 38px;
    letter-spacing: 0em;

    font-variation-settings: 'opsz' auto;
    font-feature-settings: 'kern' on;
    background: linear-gradient(90deg, #ff3e21 0%, #ffaa18 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.getSummaryNoti {
    p {
        margin-bottom: 0;

        b {
            color: var(--ant-color-primary);
        }

        svg {
            transform: translateY(2px);
        }
    }

    :global(.ant-notification-notice-icon) {
        font-size: 14px !important;
        margin-top: 5px !important;
    }

    :global(.ant-notification-notice-description),
    :global(.ant-notification-notice-message) {
        margin-left: 18px !important;
    }
}

a {
    &:hover {
        color: var(--ant-color-primary) !important;
    }
}

.wenxianGuide {
    width: 600px;
    max-width: 90vw;

    img {
        width: 100%;
        margin: 4px 0;
    }
}

.summaryTips {
    position: absolute;
    right: -260px;
    transition: all ease 300ms;
    border: 1px solid rgb(229, 231, 235);
    box-sizing: border-box;
    padding: 12px;
    border-radius: 6px;
    background: linear-gradient(180deg, #f7f1ff 0%, #ffffff 74%);
    box-shadow: 0px 0px 14px 0px rgba(32, 0, 85, 0.05);

    .bold {
        font-weight: 700;
        line-height: 24px;
    }

    .line {
        display: flex;
        align-items: center;
        line-height: 24px;
    }

    span {
        color: var(--ant-color-primary);
    }
}

.mr8 {
    margin-right: 8px;
}

// 移动端
@media screen and (max-width: 768px) {
    .documents {
        .content {
            padding: 40px 0 20px 0;
            .title {
                margin-bottom: 12px;
            }

            .subTitle {
                font-size: 12px;
                margin-bottom: 24px;
            }

            .opts {
                margin: 40px 0 0 0;
            }

            .stepFormContainer {
                padding: 20px 20px;
            }

            .listDesc {
                width: 100%;
                padding: 32px 0 0 0;

                .listItem {
                    width: 100%;
                    margin: 8px 0;
                }
            }

            .policyCheck {
                width: 100%;
                margin: 16px 0 20px 0;
            }

            .ringingBox {
                margin-bottom: 16px;
                margin-top: 20px;
            }
        }
    }

    .bannerText {
        font-size: 16px;
        line-height: 22px;
    }

    .ultraBannerText {
        font-size: 16px;
        line-height: 22px;
    }
}

.docTypeContainer {
    display: flex;
    gap: 12px;
    overflow-x: auto;
    padding: 10px;
    margin: -10px; // 抵消padding，确保对齐

    @media (min-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;
        overflow: visible;
    }

    .docTypeItem {
        flex-shrink: 0;
        cursor: pointer;
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 8px 16px;
        border-radius: 12px;
        background: white;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        transform-origin: center;

        @media (min-width: 768px) {
            flex-shrink: 1;
            padding: 16px;
        }

        &:hover {
            transform: scale(1.1);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
            z-index: 1;
        }

        &.active {
            color: var(--ant-color-primary);
        }

        &:not(.active) {
            background-color: rgba(255, 255, 255, 0.5);
            color: #666;
        }

        img {
            width: 18px;
            height: 18px;
            transition: all 0.3s ease;
        }

        span {
            white-space: nowrap;
            transition: all 0.3s ease;
        }
    }
}

// 添加相邻元素的动画效果
.docTypeItem:hover ~ .docTypeItem {
    transform: translateX(10px);
}
