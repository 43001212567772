.sectionTitle {
  font-size: 16px;
  line-height: 22px;
  color: var(--ant-color-primary);
  font-weight: 700;
  margin: 32px 0 16px 0;
}

.sectionDesc {
  max-width: 90vw;

  span {
    color: var(--ant-color-primary);
  }
}

.docTitle {
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 16px 0;
}

.docType {
  font-size: 16px;
  line-height: 20px;
}

.regenBtn {
  font-size: 12px;
  color: #8C93B1;
  margin: 8px 0 0 0;
}
