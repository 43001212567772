.NavRight {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    width: 36px;
    height: 36px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    margin-right: 24px;
    cursor: pointer;
  }

  .avatarIcon {
    background-color: #fff;
  }

  .btn {
    border-color: #000;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.4);
  }

  .navBtn {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin-right: 24px;
    cursor: pointer;
    border-radius: 4px;
    color: #333333;

    img {
      width: 16px;
      height: 16px;
      object-fit: cover;
    }
    .text {
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      margin-top: 4px;
    }
    &:hover {
    }
  }
}

.menuInfoRow {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 22px;
  overflow: hidden;
  width: 180px;

  .menuAvatarIcon {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }

  .avatarRight {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .username {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .text {
  }
}

.memberDot {
  margin-bottom: 12px;
  color: #999999;
  font-size: 12px;
  display: block;
}

.memberBox {
  background: url("../assets/user/bg.png") no-repeat;
  width: 180px;
  height: 56px;
  display: block;
  background-size: 100% 100%;
  padding: 8px;
  box-sizing: border-box;

  .memberTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .memberPic {
      width: auto;
      height: 12px;
      display: block;
    }

    .memberBtn {
      width: 52px;
      height: 18px;
      border-radius: 9px;
      padding: 0px 6px;
      box-sizing: border-box;
      font-size: 10px;
      font-weight: 500;
      line-height: 18px;
      color: #5e3300;

      background: linear-gradient(271deg, #fdc553 3%, #ffd070 114%);
    }
  }

  .memberDesc {
    font-size: 10px;
    line-height: 18px;
    color: #c48e4d;
  }
}

.linkRow {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0em;
  color: #333333;

  .linkName {
    margin: 0 0 0 6px;
  }
}

.modian {
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  .count {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #f7941d;
  }
  .tip {
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: normal;
    line-height: 20px;
    color: #333333;
  }
}
