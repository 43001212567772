.modalTitle {
  display: flex;
  justify-content: center;
}

.carouselWrapper {
  position: relative;
  padding: 0 48px;
}

.carouselContainer {
  padding: 0 48px;
}

.prevButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.prevButton {
  left: 0;
}

.nextButton {
  right: 0;
}

.slideContent {
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slideImage {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.slideTitle {
  margin-top: 12px;
  text-align: center;
}

.slideLabel {
  display: flex;
  justify-content: center;
  margin: 16px 0;
  font-weight: 600;
  font-size: 16px;
}

.actionContainer {
  display: flex;
  justify-content: center;
}
