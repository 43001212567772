.drawer {
    .summaryBox {
        margin-top: 0;
    }
}
.summaryWrapper {
    position: relative;
    width: 100%;
    padding: 32px;
    .summaryBox {
        margin-top: 0;
    }
}

.docTitle {
    font-size: 24px;
    line-height: 28px;
    display: flex;
    justify-content: center;
    margin: 32px 0;
    font-weight: 700;
}

.summaryBox {
    //border: 1px solid rgb(229, 231, 235);
    border-radius: 8px;
    padding: 16px;
    max-width: 1200px;
    margin: 0 auto;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);

    .summaryHeader {
        color: #333;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div:first-child {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    .summarySection {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 8px 12px 48px;
        cursor: pointer;

        .sectionLeft {
        }
        .sectionTitle {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
        }

        .ml4 {
            margin-left: 4px;
        }
        .sectionAbstract {
            color: rgb(102, 102, 102);
            font-size: 14px;
            line-height: 21px;
            margin: 8px 0 0 0;
        }

        &:hover {
            background-color: rgb(243, 243, 245);
        }
    }

    .collaspseSection {
        padding-left: 28px;
        cursor: pointer;
        .cSectionHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 0;
        }

        .cSectionTitle {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .title {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                display: flex;
                align-items: center;
            }
            .desc {
                color: rgb(102, 102, 102);
                font-size: 14px;
                line-height: 21px;
            }
        }

        .subSection {
            padding: 10px 6px 10px 48px;
            display: flex;
            justify-content: space-between;
            &:hover {
                background-color: rgb(243, 243, 245);
            }
        }

        .subSectionTitle {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            margin: 0 0 8px 0;
        }

        .subSectionAbs {
            color: rgb(102, 102, 102);
            font-size: 14px;
            line-height: 21px;
        }
    }

    .collaspseSectionSingle {
        padding: 10px 6px 10px 48px;
        .cSectionTitle {
            .title {
                margin: 0 0 8px 0;
            }
        }
    }

    :global(.ant-collapse-header) {
        padding: 10px 6px !important;
        border-radius: 0 !important;
        align-items: center !important;

        &:hover {
            background-color: rgb(243, 243, 245);
        }
    }

    :global(.ant-collapse-content-box) {
        padding: 0 !important;
    }
}

.summaryHeader {
    display: flex;
    align-items: center;
    gap: 8px;
}

.cSectionTitle {
    .title {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}
