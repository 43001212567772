.orderModal {
    .content {
        border: 1px solid rgb(229, 231, 235);
        margin: 16px 0 16px 0;
        border-radius: 8px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        color: #8c93b1;
        font-size: 12px;
        background: #f8fbff;
        padding: 8px 12px;
        border-bottom: 1px solid #e9e9ea;
    }

    .paperInfo {
        padding: 12px;
        display: flex;
        gap: 12px;

        .title {
            color: #192038;
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
        }
    }

    .divider {
        margin: 8px 0;
    }
    .list {
        .listRow {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: #8c93b1;

            .listTitle {
                font-weight: 500;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
    }
}
