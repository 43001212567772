.summaryProgress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .progressTip {
        color: #666;
        margin: 24px 0;
        max-width: 90vw;
    }

    :global(.ant-progress-inner) {
        width: 200px !important;
        height: 200px !important;
    }

    :global(.ant-progress-status-success) {
        :global(.ant-progress-text) {
            color: var(--ant-color-primary) !important;
        }
    }

    :global(.ant-progress-text) {
        font-size: 14px !important;
        white-space: pre-line !important;
    }
}

.drawer {
    .summaryBox {
        margin-top: 0;
    }
}
.summaryWrapper {
    position: relative;
    width: 100%;
    .summaryBox {
        margin-top: 0;
    }
}

.summaryBox {
    width: 100%;
    //border: 1px solid rgb(229, 231, 235);
    border-radius: 8px;
    padding: 16px;
    max-width: 90vw;

    .summaryHeader {
        color: #333;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .summarySection {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 8px 12px 48px;
        cursor: pointer;

        .sectionLeft {
        }
        .sectionTitle {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
        }

        .ml4 {
            margin-left: 4px;
        }
        .sectionAbstract {
            color: rgb(102, 102, 102);
            font-size: 14px;
            line-height: 21px;
            margin: 8px 0 0 0;
        }

        &:hover {
            background-color: rgb(243, 243, 245);
        }
    }

    .collaspseSection {
        padding-left: 28px;
        cursor: pointer;
        .cSectionHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 0;
        }

        .cSectionTitle {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .title {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                display: flex;
                align-items: center;
            }
            .desc {
                color: rgb(102, 102, 102);
                font-size: 14px;
                line-height: 21px;
            }
        }

        .subSection {
            padding: 10px 6px 10px 48px;
            display: flex;
            justify-content: space-between;
            &:hover {
                background-color: rgb(243, 243, 245);
            }
        }

        .subSectionTitle {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            margin: 0 0 8px 0;
        }

        .subSectionAbs {
            color: rgb(102, 102, 102);
            font-size: 14px;
            line-height: 21px;
        }
    }

    .collaspseSectionSingle {
        padding: 10px 6px 10px 48px;
        .cSectionTitle {
            .title {
                margin: 0 0 8px 0;
            }
        }
    }

    :global(.ant-collapse-header) {
        padding: 10px 6px !important;
        border-radius: 0 !important;
        align-items: center !important;

        &:hover {
            background-color: rgb(243, 243, 245);
        }
    }

    :global(.ant-collapse-content-box) {
        padding: 0 !important;
    }
}

.summaryTips {
    position: absolute;
    right: -180px;
    transition: all ease 300ms;
    border: 1px solid rgb(229, 231, 235);
    box-sizing: border-box;
    padding: 8px;
    border-radius: 6px;
    background: linear-gradient(180deg, #f7f1ff 0%, #ffffff 74%);
    box-shadow: 0px 0px 14px 0px rgba(32, 0, 85, 0.05);

    .bold {
        font-weight: 700;
        line-height: 24px;
    }

    .line {
        display: flex;
        align-items: center;
        line-height: 24px;
    }

    span {
        color: var(--ant-color-primary);
    }
}

.label {
    font-size: 14px;
    font-weight: normal;
    margin: 12px 0;
    b {
        color: red;
        margin-left: 4px;
    }
}
