.tiptap {
    * {
        line-height: 2.25;
    }
}

.academic-heading {
    user-select: text; /* 允许文本选择 */
    cursor: pointer; /* 显示指针样式 */
    background: transparent;
    transition: background-color 0.2s;

    &::selection {
        background-color: rgba(0, 0, 0, 0.1); /* 选中时的背景色 */
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.05); /* 悬停时的背景色 */
    }
}

.academic-editor {
    max-width: 1000px;
    margin: 0 auto;
    height: 80vh;
    overflow-y: auto;
    // padding: 1rem;
    font-size: 14px;
    line-height: 1.5;
}

/* 标题样式 */
.academic-editor h1 {
    font-size: 21px;
    line-height: 3;
    text-align: center;
}

.academic-editor h2 {
    font-size: 21px;
    line-height: 3;
    text-align: center;
}

.academic-editor h3 {
    font-size: 17px;
}

.academic-editor h4 {
    font-size: 14px;
    margin: 0.6rem 0;
    font-weight: bold;
}

/* 段落样式 */
.academic-editor p {
    margin: 0.9rem 0;
    line-height: 1.6;
}

/* 表格样式 */
.academic-editor table {
    font-size: 13px;
    border-collapse: collapse;
    margin: 0.5rem 0;
    width: 100%;
}

.academic-editor th,
.academic-editor td {
    border: 1px solid #ddd;
    padding: 6px 8px;
    text-align: left;
}

.academic-editor th {
    background-color: #f5f5f5;
    font-weight: bold;
}

/* 图表容器样式 */
.echarts-container {
    width: 100%;
    height: 300px;
    margin: 0.5rem 0;
}

/* 引用文献样式 */
.reference-list {
    font-size: 12px;
    margin: 0.5rem 0;
}

.reference-item {
    margin: 0.3rem 0;
    padding-left: 2em;
    text-indent: -2em;
}

/* 摘要和致谢部分样式 */
.abstract-zh,
.abstract-en,
.acknowledgements {
    margin: 1rem 0;
}

/* Tiptap 编辑器特定样式 */
.ProseMirror {
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
}

.ProseMirror p {
    margin: 0.5rem 0;
}

.ProseMirror table {
    font-size: 13px;
    border-collapse: collapse;
    margin: 0.5rem 0;
    width: 100%;
}

.ProseMirror td,
.ProseMirror th {
    border: 1px solid #ddd;
    padding: 6px 8px;
    min-width: 1em;
    position: relative;
    vertical-align: top;
}

.ProseMirror th {
    background-color: #f5f5f5;
    font-weight: bold;
}

/* 图片样式 */
.academic-image {
    max-width: 100%;
    height: auto;
    margin: 0.5rem 0;
}

.ProseMirror sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: super;
    color: #666;
}

.bubble-menu {
    display: flex;
    flex-direction: row;
    background-color: #0d0d0d;
    padding: 0.5rem;
    border-radius: 0.5rem;

    .menu-button {
        border: none;
        background: none;
        color: #fff;
        padding: 0.2rem 0.4rem;
        margin: 0 0.2rem;
        font-size: 0.9rem;
        cursor: pointer;
        border-radius: 0.3rem;
        white-space: nowrap;

        &:hover {
            background-color: #2d2d2d;
        }
    }
}

/* 确保选区样式始终可见 */
.ProseMirror ::selection {
    background: rgba(162, 119, 238, 0.3) !important;
}

.ProseMirror:not(:focus) ::selection {
    background: rgba(162, 119, 238, 0.3) !important;
}

/* 防止文本选择被清除 */
.ProseMirror {
    user-select: text !important;
    -webkit-user-select: text !important;
}

.tiptap {
    :first-child {
        margin-top: 0;
    }

    img {
        margin: 0 auto;
        zoom: 0.4;
        &.ProseMirror-selectednode {
            outline: 3px solid #9b79e7;
        }
    }
    sup {
        display: inline-block;
        transform: translateY(6px) !important;
    }
}
