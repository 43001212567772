.ultraDialog {
  :global(.ant-modal-body) {
    height: 630px;
    overflow-y: auto;
  }

  :global(.ant-table-cell) {
    vertical-align: top;
  }

  .title {
    font-weight: 800;
    font-size: 16px;
    margin: 12px 0 8px;
  }
  .highlight {
    padding: 12px 16px 1px;
    border-radius: 8px;
    background-color: rgba(102, 102, 102, 0.08);
    p {
      margin: 0 0 12px 0;
      b {
        color: #f7941d;
      }
    }
  }
  .ultraColumn {
    color: #f7941d;
    font-weight: bold;
  }
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    margin: 12px 0 8px 0;

    i {
      display: inline-block;
      width: 18px;
      height: 18px;
      background-color: #f7941d;
      text-align: center;
      border-radius: 50%;
      color: #fff;
      margin-right: 4px;
      font-size: 12px;
    }
    .desc {
      width: 120px;
      font-weight: bold;
      color: #f7941d;
    }
  }
  .normalText {
    color: #999999;
  }

  .ultraText {
    white-space: pre-line;
  }
}
